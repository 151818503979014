<template>
  <v-container>
    <confirm-dialog
      :show="dialogDelete"
      :confirm-click="deleteProject"
      @close="closeConfirmationDialog"
    >
      <div class="mb-2">
        <span>Delete project</span>
      </div>
      <div class="body-1">
        <span class="font-weight-regular">
          Are you sure that you want to delete this project?
        </span>
        <span class="font-weight-medium">This action cannot be undone</span>
        <span class="font-weight-regular">
          and the coin balance of the project will be set to zero.
        </span>
      </div>
    </confirm-dialog>
    <confirm-dialog
      :show="dialogChangePhase"
      :confirm-click="checkAndSave"
      @close="closeConfirmationDialog"
    >
      <div v-if="goNextPhase">
        Are you sure you want to change
        the phase to "{{ selectedPhaseTitle }}"?
      </div>
      <div v-else>
        <div>Are you sure you want to go back to the phase "{{ selectedPhaseTitle }}"?</div>
        <div class="subtitle-1 my-2">
          The data added in the current phase will not be lost.
          It will appear once the project is moved again to the current phase.
        </div>
      </div>
    </confirm-dialog>
    <v-row>
      <v-col
        cols="12"
        md="10"
        xl="5"
      >
        <v-card v-if="project">
          <v-container class="pa-6">
            <select-image
              ref="selectImage"
              :initial-image="project.photo"
              :disabled="hasSubmitIntention"
              :width="240"
              :height="135"
              required
              class="mb-4"
            />
            <v-text-field
              ref="title"
              v-model="title"
              v-ruid="'title'"
              :rules="[rules.required]"
              :disabled="hasSubmitIntention"
              clearable
              label="Title *"
              placeholder="Enter a title..."
              required
            />
            <v-text-field
              ref="tagline"
              v-model="tagline"
              v-ruid="'tagline'"
              :rules="[rules.required]"
              :disabled="hasSubmitIntention"
              label="Tagline *"
              clearable
              placeholder="Enter a tagline..."
              required
            />
            <v-text-field
              v-if="!project.isRejected && isNotRedBoxIdea"
              ref="fundingGoal"
              v-model.number="fundingGoal"
              v-ruid="'fundingGoal'"
              :rules="[rules.required, rules.positiveNumber]"
              :disabled="hasSubmitIntention"
              label="Funding Goal *"
              clearable
              placeholder="Enter a funding goal..."
              required
              type="number"
            />
            <label>Creator</label>
            <p>{{ form.creator.name }}</p>

            <label>Creation Date</label>
            <p :class="{ 'mb-0': creatorDeleted }">
              {{ creationDate }}
            </p>

            <template v-if="!creatorDeleted">
              <label>Creator Email</label>
              <p
                :class="teamMemberFeature ? 'mb-0' : 'mb-4'"
              >
                {{ form.creator.email }}
              </p>
            </template>

            <change-owner-option
              :project="project"
              @click="showChangeOwnerDialog = true"
            />
            <change-owner
              v-if="showChangeOwnerDialog"
              :show="showChangeOwnerDialog"
              :project="project"
              @confirm="ownerChanged"
              @close="showChangeOwnerDialog = false"
            />

            <div v-if="form.projectUnit">
              <label>Project Unit</label>
              <p>{{ form.projectUnit.name }}</p>
            </div>

            <team-member-list
              v-if="teamMemberFeature"
              class="mb-5"
              :items="form.teamMembers"
            />

            <template v-if="!project.isRejected">
              <v-autocomplete
                v-model="interestedUnit"
                v-ruid="'unitInterested'"
                clearable
                :disabled="hasSubmitIntention"
                :items="units"
                item-text="name"
                item-value="id"
                label="Interested Unit"
                no-data-text="No units available"
                placeholder="Select an interested unit..."
                return-object
              />
            </template>
            <template v-if="campaignsEnabled">
              <v-autocomplete
                v-model="campaign"
                v-ruid="'campaigns'"
                clearable
                :disabled="hasSubmitIntention"
                :items="campaigns"
                item-text="title"
                label="Campaign"
                no-data-text="No campaigns available"
                :placeholder="campaignPlaceHolder"
                return-object
              />
            </template>
            <template v-if="crossCorporateEnabled && !project.isRejected">
              <v-autocomplete
                v-model="crossCorporate"
                v-ruid="'crossCorporate'"
                clearable
                :disabled="hasSubmitIntention"
                :items="companies"
                item-text="name"
                item-value="id"
                label="Cross-Corporate"
                multiple
                no-data-text="No cross-corporate companies available"
                placeholder="Select cross-corporate partners..."
              />
              <template v-if="crossCorporate && crossCorporate.length">
                <label>Cross-Corporate Tags *</label>
                <v-autocomplete
                  ref="crossCorporateTagsSelected"
                  v-model="crossCorporateTagsSelected"
                  v-ruid="'crossCorporateTags'"
                  :rules="[rules.atLeastOneItem]"
                  :items="globalTags"
                  :disabled="hasSubmitIntention"
                  item-text="name"
                  return-object
                  multiple
                  clearable
                  placeholder="Select"
                />
              </template>
            </template>
            <edit-sponsors
              v-if="showSponsors"
              v-model="sponsors"
              :class="{'sponsorsError': sponsorsError}"
              @updateSponsor="updatedSponsors = $event"
              @deleteSponsor="deletedSponsors = $event"
              @focus="sponsorsError = false"
            />
            <select-video
              ref="video1min"
              v-model="oneMinuteVideo"
              :disabled="hasSubmitIntention"
              label="1 Minute Video"
              pitch="oneMinute"
              :project="project"
              required
              @isUploadInProgress="onOneMinuteVideoUploadProgress"
            />
            <select-video
              v-if="!project.isRejected && isNotRedBoxIdea"
              ref="video8min"
              v-model="eightMinuteVideo"
              :disabled="hasSubmitIntention"
              label="8 Minute Video"
              pitch="eightMinute"
              :project="project"
              required
              @isUploadInProgress="onEightMinuteVideoUploadProgress"
            />
            <select-video
              v-if="isBlueBox || isGoldBox"
              ref="blueboxVideo"
              v-model="blueboxVideo"
              :disabled="hasSubmitIntention"
              label="Bluebox Video"
              pitch="bluebox"
              :project="project"
              @isUploadInProgress="onBlueboxVideoUploadProgress"
            />
            <select-video
              v-if="isGoldBox"
              ref="goldboxVideo"
              v-model="goldboxVideo"
              :disabled="hasSubmitIntention"
              label="Goldbox Video"
              pitch="goldbox"
              :project="project"
              @isUploadInProgress="onGoldboxVideoUploadProgress"
            />
            <template v-if="!project.isRejected && company.features[featureNames.COINS]">
              <v-text-field
                v-model="coinBalanceDelta"
                v-ruid="'coins'"
                class="mb-2"
                clearable
                :disabled="hasSubmitIntention"
                :hint="`Current balance: ${coinBalance}`"
                label="Add or Remove Coins"
                persistent-hint
                placeholder="Enter amount..."
                type="number"
              />
            </template>
            <label
              v-if="project.isRejected"
              class="d-block mb-5"
            >
              Requested Starting Month: {{ requestedStartMonth }}
            </label>
            <template v-if="!project.isRejected">
              <label>Current Phase</label>
              <p class="mb-4">
                {{ displayCurrentPhase }}
              </p>
            </template>
            <project-duration
              v-if="displayPhaseDuration"
              :disabled="hasSubmitIntention"
              :phase="{
                start: phaseStartDate,
                end: phaseEndDate
              }"
              @value="phaseDuration = $event"
              @error="phaseDurationError = $event"
            />
            <template
              v-if="!project.isRejected
                && !isProjectAlumni
                && !isAlumniChecked"
            >
              <v-select
                v-model="selectedPhase"
                clearable
                :disabled="hasSubmitIntention"
                label="Change Phase"
                :items="availablePhases"
                item-text="text"
                item-value="name"
                placeholder="Select phase..."
              />
            </template>
            <label>Description *</label>
            <editor
              ref="description"
              class="my-4"
              :content="description"
              :disabled="hasSubmitIntention"
              :show-submit-button="false"
              :required="true"
              toolbar="minimalToolbar"
              @keyUp="description = $event"
            />
            <!-- Tags -->
            <template v-if="!project.isRejected">
              <v-autocomplete
                ref="tagsSelected"
                v-model="tagsSelected"
                v-ruid="'tags'"
                clearable
                :disabled="hasSubmitIntention"
                :rules="[rules.atLeastOneItem]"
                :items="activeTags"
                item-text="name"
                label="Tags *"
                multiple
                placeholder="Select tags..."
                return-object
              />
            </template>
            <coaching-languages v-model="coachingLanguages" />
            <!-- Coaches -->
            <select-users
              v-model="coaches"
              :source="allCoaches"
              show-admin-role
            />
            <template v-if="!project.isRejected">
              <!-- Email campaigns -->
              <v-row>
                <v-col>
                  <email-campaign
                    :had-email-campaign="hadEmailCampaign"
                    :email-campaign="hasEmailCampaign"
                    :show-coaching-number="true"
                    :disabled="hasSubmitIntention"
                    @campaignSelected="hasEmailCampaign = $event"
                    @campaignDateSelected="campaignDateSelected = $event"
                    @coachingNumber="coachingNumber = $event"
                  />
                </v-col>
                <v-col
                  v-if="emailCampaignStartDate && hasEmailCampaign"
                  cols="3"
                >
                  <email-campaign-dates
                    :subscribed="true"
                    :project="project"
                    :start-date="emailCampaignStartDate"
                  />
                </v-col>
              </v-row>
              <v-checkbox
                v-model="isAlumniChecked"
                class="alumni mt-1"
                color="primary"
                :disabled="hasSubmitIntention"
                hide-details
                label="Alumni Project"
                @change="resetValuesIfAlumni"
              />
              <!-- Alumni -->
              <template v-if="isAlumniChecked">
                <select-video
                  ref="learningsVideo"
                  v-model="learningsVideo"
                  :disabled="hasSubmitIntention"
                  label="Learnings Video"
                  pitch="learnings"
                  :project="project"
                  @isUploadInProgress="onLearningsVideoUploadProgress"
                />
                <v-text-field
                  v-model="learningsUrl"
                  class="mb-2"
                  clearable
                  :disabled="hasSubmitIntention"
                  label="Link to learnings document"
                  placeholder="Add a link to a learnings document e.g:
                  OneDrive, SharePoint, Dropbox, etc"
                />
                <label>Additional comments</label>
                <editor
                  class="my-4"
                  :content="learningsDescription"
                  :disabled="hasSubmitIntention"
                  :show-submit-button="false"
                  :required="true"
                  toolbar="minimalToolbar"
                  @keyUp="learningsDescription = $event"
                />
              </template>
            </template>
            <!-- Delete -->
            <v-checkbox
              v-model="deleteProjectChecked"
              class="mb-8"
              color="error"
              :disabled="hasSubmitIntention"
              hint="Once the project is deleted, it cannot be restored"
              label="Delete Project"
              persistent-hint
            />
            <app-button
              class="mb-4"
              :color="deleteProjectChecked ? 'error' : null"
              :loading="hasSubmitIntention"
              block
              @click="manageSubmitButton"
            >
              {{ deleteProjectChecked ? 'Delete' : 'Save' }}
            </app-button>
            <label>* Mandatory field</label>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Moment from 'moment';
import { ConfirmDialog, ValidateMixin, SelectImage, SelectUsers, Editor } from '@kickbox/common-admin';
import {
  PENDING, BLUEBOX, GOLDBOX, REDBOX_IDEA, REDBOX_FUNDING, ALUMNI, PHASE_ORDER
} from '@kickbox/common-util';
import fileService from '@kickbox/common-util/service/fileService';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import EmailCampaign from '@/components/projects/EmailCampaign';
import EmailCampaignDates from '@/components/projects/EmailCampaignDates';
import {
  phaseService,
  projectService,
  translationService,
  sponsorService,
  userService
} from '@/services';
import EditSponsors from '@/components/projects/EditSponsors';
import ProjectDuration from '@/components/projects/ProjectDuration';
import SelectVideo from '@/components/widgets/SelectVideo';
import TeamMemberList from './TeamMemberList';
import CoachingLanguages from './CoachingLanguages';
import ChangeOwner from './ChangeOwner';
import ChangeOwnerOption from '@/components/projects/ChangeOwnerOption';

export default {
  components: {
    ConfirmDialog,
    EmailCampaign,
    EmailCampaignDates,
    SelectUsers,
    EditSponsors,
    TeamMemberList,
    SelectImage,
    ProjectDuration,
    SelectVideo,
    Editor,
    CoachingLanguages,
    ChangeOwner,
    ChangeOwnerOption
  },
  mixins: [ValidateMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.goToAfterSave = from.name || 'AdminManageProject';
    });
  },
  props: ['id', 'alumni'],
  data() {
    return {
      sponsors: [],
      deletedSponsors: [],
      updatedSponsors: [],
      sponsorsError: false,
      project: null,
      selectedPhase: '',
      phaseDuration: 0,
      title: '',
      tagline: '',
      coaches: [],
      creatorDeleted: false,
      fundingGoal: 0,
      interestedUnit: null,
      tagsSelected: [],
      crossCorporateTagsSelected: [],
      crossCorporate: [],
      coinBalance: 0,
      coinBalanceDelta: 0,
      phaseFullTitle: '',
      phaseTitle: '',
      phaseStartDate: '',
      phaseEndDate: '',
      phaseSubTitle: '',
      phaseDurationError: false,
      description: '',
      requestedStartMonth: '',
      pendingPhase: PENDING.title,
      blueBoxPhase: BLUEBOX.title,
      goldBoxPhase: GOLDBOX.title,
      deleteProjectChecked: false,
      dialogDelete: false,
      dialogChangePhase: false,
      isAlumniChecked: false,
      isProjectAlumni: false,
      campaign: '',
      featureNames: FEATURE_NAME,
      hasEmailCampaign: false,
      hadEmailCampaign: false,
      campaignDateSelected: new Date().toISOString().substr(0, 10),
      coachingNumber: 1,
      emailCampaignStartDate: null,
      oneMinuteVideo: '',
      eightMinuteVideo: '',
      blueboxVideo: '',
      goldboxVideo: '',
      learningsVideo: '',
      goToAfterSave: null,
      oneMinuteVideoUploadProgress: false,
      eightMinuteVideoUploadProgress: false,
      blueboxVideoUploadProgress: false,
      goldboxVideoUploadProgress: false,
      learningsVideoUploadProgress: false,
      hasSubmitIntention: false,
      learningsUrl: '',
      learningsDescription: '',
      coachingLanguages: [],
      showChangeOwnerDialog: false,
      form: {
        creator: {},
        teamMembers: [],
        projectUnit: {
          name: 'no unit'
        }
      }
    };
  },
  computed: {
    ...mapGetters([
      'companiesMap',
      'companies',
      'campaigns',
      'company',
      'phases',
      'projectById',
      'phaseByName',
      'activeTags',
      'globalTags',
      'units',
      'allCoaches'
    ]),
    formMandatoryFields() {
      return {
        title: this.title,
        tagline: this.tagline,
        fundingGoal: this.fundingGoal,
        description: this.description,
        tagsSelected: this.tagsSelected,
        sponsors: this.sponsors,
        ...this.crossCorporate && { crossCorporateTagsSelected: this.crossCorporateTagsSelected }
      };
    },
    formFields() {
      return {
        title: this.title,
        tagline: this.tagline,
        interestedUnit: this.interestedUnit,
        video1min: this.oneMinuteVideo,
        video8min: this.eightMinuteVideo,
        blueboxVideo: this.blueboxVideo,
        goldboxVideo: this.goldboxVideo,
        learningsVideo: this.learningsVideo,
        fundingGoal: this.fundingGoal,
        description: this.description,
        isAlumni: this.isAlumniChecked,
        coaches: this.coaches,
        crossCorporateTags: this.crossCorporateTagsSelected,
        campaign: this.campaign,
        tags: this.tagsSelected,
        learningsUrl: this.learningsUrl,
        learningsDescription: this.learningsDescription,
        coachingLanguages: this.coachingLanguages
      };
    },
    displayCurrentPhase() {
      return this.isProjectAlumni ? `${ALUMNI.title} (${this.phaseTitle})` : this.phaseTitle;
    },
    kickCoinLabel() {
      return `Add/Remove coins. Current balance: ${this.coinBalance}`;
    },
    availablePhases() {
      return this.phases.filter((phase) => phase.name !== this.phaseName);
    },
    displayPhaseDuration() {
      if (this.selectedPhase === BLUEBOX.name || this.selectedPhase === GOLDBOX.name) {
        return false;
      }

      return !(
        this.project.isRejected
        || this.phaseName === BLUEBOX.name
        || this.phaseName === GOLDBOX.name);
    },
    selectedPhaseTitle() {
      if (this.selectedPhase) {
        const selectedPhase = this.phases.find((phase) => phase.name === this.selectedPhase);
        return selectedPhase.text;
      }
      return '';
    },
    isNotRedBoxIdea() {
      if (this.selectedPhase) {
        return this.selectedPhase !== REDBOX_IDEA.name;
      }
      return this.phaseSubTitle !== REDBOX_IDEA.subTitle;
    },
    isNotRedBoxFunding() {
      if (this.selectedPhase) {
        return this.selectedPhase !== REDBOX_FUNDING.name;
      }
      return this.phaseSubTitle !== REDBOX_FUNDING.subTitle;
    },
    isBlueBox() {
      return this.phaseTitle === this.blueBoxPhase || this.selectedPhase === BLUEBOX.name;
    },
    isGoldBox() {
      return this.phaseTitle === this.goldBoxPhase || this.selectedPhase === GOLDBOX.name;
    },
    showSponsors() {
      return !this.project.isRejected && this.isNotRedBoxIdea && this.isNotRedBoxFunding;
    },
    campaignPlaceHolder() {
      return this.campaigns.length > 0 ? 'Select a campaign...' : 'Create a campaign first';
    },
    crossCorporateEnabled() {
      return this.company.features[FEATURE_NAME.CROSS_CORPORATE];
    },
    campaignsEnabled() {
      return this.company.features[FEATURE_NAME.CAMPAIGNS];
    },
    uploadInProgress() {
      return this.oneMinuteVideoUploadProgress
          || this.eightMinuteVideoUploadProgress
          || this.blueboxVideoUploadProgress
          || this.goldboxVideoUploadProgress
          || this.learningsVideoUploadProgress;
    },
    createdSponsors() {
      return this.sponsors.filter((s) => !s.id);
    },
    photo() {
      return this.$refs.selectImage.image;
    },
    goNextPhase() {
      const nextPhase = PHASE_ORDER[this.selectedPhase];
      const currentPhase = PHASE_ORDER[this.phaseName];
      return nextPhase > currentPhase;
    },
    creationDate() {
      return new Moment(this.project.createdAt).format('MMM DD, YYYY');
    },
    teamMemberFeature() {
      return this.company.features[this.featureNames.TEAM_MEMBERS];
    }
  },
  watch: {
    sponsors: {
      deep: true,
      handler() {
        if (this.sponsorsError) {
          this.sponsorsError = !this.validateSponsorsEntries();
        }
      }
    },
    async selectedPhase() {
      if (!this.sponsors.length
          && (this.selectedPhase === BLUEBOX.name || this.selectedPhase === GOLDBOX.name)) {
        this.sponsors = await projectService.getSponsorsForProject(this.project, true);
      }
    }
  },
  async created() {
    this.project = this.projectById(this.id);
    if (!this.project) {
      this.project = await projectService.fetchProjectById(this.id);
    }
    this.title = this.project.title;
    this.tagline = this.project.tagline;
    this.form.creator = this.project.creator;
    this.coaches = this.project.coaches;
    this.requestedStartMonth = this.project.startMonth.format('MMMM YYYY');
    this.creatorDeleted = !this.project.creator.active;
    this.fundingGoal = this.project.fundingGoal;
    this.interestedUnit = this.project.interestedUnit;
    this.crossCorporate = this.project.crossCorporate
      // Filter out disabled/remove companies
      .filter((company) => !!this.companiesMap.get(company.id))
      .map(({ id }) => id);
    this.oneMinuteVideo = this.project.videos.redboxPitch;
    this.eightMinuteVideo = this.project.videos.redboxFundingPitch;
    this.blueboxVideo = this.project.videos.blueboxPitch;
    this.goldboxVideo = this.project.videos.goldboxPitch;
    this.learningsVideo = this.project.videos.learningsVideo;
    this.learningsUrl = this.project.learningsUrl;
    this.learningsDescription = this.project.learningsDescription;
    this.coinBalance = this.project.coinBalance || 0;
    this.phaseId = this.project.phase.id;
    this.phaseName = this.project.phase.name;
    this.phaseTitle = this.project.phase.title;
    this.phaseStartDate = this.project.phase.startDate;
    this.phaseEndDate = this.project.phase.endDate;
    this.phaseSubTitle = this.project.phase.subTitle;
    this.form.projectUnit = this.project.projectUnit;
    this.description = this.project.description;
    this.isAlumniChecked = this.project.isAlumni;
    this.isProjectAlumni = this.project.isAlumni;
    this.emailCampaignStartDate = this.project.emailCampaign;
    this.hadEmailCampaign = !!this.project.emailCampaign;
    this.hasEmailCampaign = this.hadEmailCampaign;
    this.campaign = this.project.campaign;
    this.tagsSelected = this.project.tags;
    this.crossCorporateTagsSelected = this.project.crossCorporateTags;
    this.disabled = this.project.disabled;
    this.coachingLanguages = this.project.coachingLanguages;
    this.sponsors = await projectService.getSponsorsForProject(this.project);
    this.form.teamMembers = await projectService.getTeamMembersForProject(this.id);
    if (this.alumni) {
      this.isAlumniChecked = true;
      this.$vuetify.goTo('.alumni');
    }
  },
  mounted() {
    window.addEventListener('beforeunload', (e) => {
      const message = 'Upload is in progress... Do you want to cancel it?';
      // eslint-disable-next-line no-alert
      if (this.uploadInProgress && !window.confirm(message)) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    const message = 'Upload is in progress... Do you want to cancel it?';
    if (this.uploadInProgress) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(message)) {
        next(false);
        return;
      }

      this.hasSubmitIntention = false;

      if (this.$refs.video1min) {
        // always null check to prevent error on page refresh
        this.$refs.video1min.$refs.video.cancelUpload();
      }
      if (this.$refs.video8min) {
        // always null check to prevent error on page refresh
        this.$refs.video8min.$refs.video.cancelUpload();
      }
      if (this.$refs.blueboxVideo) {
        // always null check to prevent error on page refresh
        this.$refs.blueboxVideo.$refs.video.cancelUpload();
      }
      if (this.$refs.goldboxVideo) {
        // always null check to prevent error on page refresh
        this.$refs.goldboxVideo.$refs.video.cancelUpload();
      }
      if (this.$refs.learningsVideo) {
        // always null check to prevent error on page refresh
        this.$refs.learningsVideo.$refs.video.cancelUpload();
      }
    }
    next();
  },
  methods: {
    ...mapMutations([
      'updateProject',
      'updateRejectedProject'
    ]),
    updateProjectStore(projectProps) {
      if (this.project.isRejected) {
        return this.updateRejectedProject(projectProps);
      }

      return this.updateProject(projectProps);
    },
    closeConfirmationDialog() {
      this.dialogDelete = false;
      this.dialogChangePhase = false;
    },
    manageSubmitButton() {
      // No need to check form on deleting
      if (this.deleteProjectChecked) {
        this.selectedPhase = null;
        this.dialogDelete = true;
        return true;
      }

      this.sponsorsError = !this.isSponsorsValid();

      ['video1min', 'video8min', 'blueboxVideo', 'goldboxVideo', 'learningsVideo']
        .forEach((videoRefId) => this.$refs[videoRefId]?.$refs.video.validate());

      if (!this.checkFields() || this.sponsorsError) {
        this.displayMandatoryFieldsWarning();
        return false;
      }

      if (this.selectedPhase) {
        this.dialogChangePhase = true;
        this.phaseDuration = 0;
      } else {
        this.checkAndSave();
      }
      return true;
    },
    isEightMinuteVideoMissing() {
      const result = !this.project.isRejected && this.isNotRedBoxIdea && !this.eightMinuteVideo;
      if (result) {
        this.$refs.video8min.$refs.video.clearStoredVideo();
      }
      return result;
    },
    isSponsorsValid() {
      if (this.showSponsors && !this.isAlumniChecked) {
        if (this.sponsors.length === 0) {
          this.sponsorsError = true;
          this.$store.dispatch('showSnackBar', {
            text: 'Please, add at least one sponsor to your project.'
          });
          return false;
        }

        if (!this.validateSponsorsEntries()) {
          this.sponsorsError = true;
          this.displayMandatoryFieldsWarning();
          return false;
        }
      }
      return true;
    },
    validateSponsorsEntries() {
      return !this.sponsors
        .some((sponsor) => !sponsor.name.length || !sponsor.amount > 0 || !sponsor.unit);
    },
    checkAndSave() {
      if (!this.photo.hasImage()) {
        this.$store.dispatch('showSnackBar', {
          text: 'You need to add a project picture.'
        });
        return;
      }

      this.sponsorsError = !this.isSponsorsValid();

      if (!this.oneMinuteVideo || this.isEightMinuteVideoMissing() || this.phaseDurationError) {
        // this should be before the checkFields()
        this.$store.dispatch('showSnackBar', { text: 'Please, fix the input errors.' });
        return;
      }

      this.hasSubmitIntention = true;
      if (!this.uploadInProgress) {
        this.submit();
      }
    },
    displayMandatoryFieldsWarning() {
      this.$store.dispatch('showSnackBar', { text: 'Please, fill in all the mandatory fields.' });
    },
    async submit() {
      const validFields = this.verifyFieldsValue({
        ...this.formFields,
        crossCorporate: this.crossCorporate,
        tags: this.tagsSelected
      });

      if (!validFields) {
        this.hasSubmitIntention = false;
        this.displayMandatoryFieldsWarning();
        return;
      }

      // in catch block, this.$refs.videoUpload is undefined
      const uploadCancelledErrorMessage = this.$refs.video1min
        .$refs.video.getUploadCancelledErrorMessage();
      // starts video upload simultaneously
      let result = Promise.all([
        this.$refs.video1min.$refs.video.save()
          .then((video) => {
            this.oneMinuteVideo = video;
          }),
        !this.project.isRejected && this.isNotRedBoxIdea && this.$refs.video8min.$refs.video.save()
          .then((video) => {
            this.eightMinuteVideo = video;
          }),
        (this.isBlueBox || this.isGoldBox) && this.$refs.blueboxVideo.$refs.video.save()
          .then((video) => {
            this.blueboxVideo = video;
          }),
        this.isGoldBox && this.$refs.goldboxVideo.$refs.video.save()
          .then((video) => {
            this.goldboxVideo = video;
          }),
        this.isAlumniChecked && this.$refs.learningsVideo.$refs.video.save()
          .then((video) => {
            this.learningsVideo = video;
          })
      ]);
      if ((this.selectedPhase && this.goNextPhase)
          || (this.isAlumniChecked && !this.project.isAlumni)
      ) {
        // show the Certificate popup after save
        this.$store.commit('setProjectCertificate', {
          phaseFullTitle: `${this.phaseTitle} ${this.phaseSubTitle || ''}`.trim(),
          projectId: this.project.id
        });
      }
      if (this.deletedSponsors.length) {
        result = result.then(() => this.deletedSponsors
          .forEach((sponsor) => sponsorService.deleteSponsor(sponsor.id)));
      }
      if (this.updatedSponsors.length) {
        result = result.then(() => this.updatedSponsors.forEach((sponsor) => sponsorService
          .updateSponsor(sponsor.id, sponsor.name, sponsor.unit.name, sponsor.amount)));
      }
      if (this.createdSponsors.length) {
        result = result.then(async () => {
          const createdSponsors = await Promise.all(this.createdSponsors
            .map(async (sponsor) => sponsorService
              .createSponsor(this.project.id, sponsor.name, sponsor.unit.name, sponsor.amount)));

          // add ids to sponsors array to update store correctly
          this.sponsors = this.sponsors.filter((s) => s.id);
          this.sponsors = this.sponsors.concat(createdSponsors.map((s) => ({
            id: s.id,
            name: s.get('name'),
            unit: s.get('unit'),
            amount: s.get('amount')
          })));
          this.updateProjectStore({
            id: this.project.id,
            sponsors: this.sponsors
          });
        });
      }

      if (this.selectedPhase) {
        result = result.then(this.changeProjectPhase);
      }
      if (this.isAlumniChecked !== this.project.isAlumni) {
        result = result.then(() => projectService.manageAlumni(
          this.project, this.isAlumniChecked && !this.project.isAlumni
        ));
      }
      result = result.then(this.manageEmailCampaign);
      if (this.phaseDuration !== 0) {
        result = result.then(this.manageProjectPhaseDuration);
      }
      if (this.coinBalanceDelta > 0) {
        result = result.then(this.addCreditFromProject);
      } else if (this.coinBalanceDelta < 0) {
        result = result.then(this.removeCreditFromProject);
      }
      if (this.crossCorporate.length) {
        result = result.then(this.updateCrossCorporate);
      } else if (!this.crossCorporate.length && this.project.crossCorporate.length) {
        result = result.then(this.removeCrossCorporate);
      }

      result = result
        .then(() => this.getValidatedEntityProperties(this.formFields))
        .then((entity) => this.saveProject(entity));

      result
        .catch((error) => {
          if (error.message !== uploadCancelledErrorMessage) {
            throw error;
          }
        })
        .finally(() => {
          translationService.clearTranslation(`Project-${this.project.id}`);
        });
    },
    deleteProject() {
      this.closeConfirmationDialog();
      projectService.deleteProject(this.project.id);
      this.$router.push({ name: this.goToAfterSave });
    },
    async saveProject(entity) {
      const { setProperties, unsetProperties } = entity;

      // If image is not the same
      if (!this.photo.currentIsInitial && this.photo.hasImage()) {
        const objectInfo = {
          type: 'Project',
          id: this.id,
          field: 'photo'
        };
        const location = await fileService
          .uploadImage(this.photo, this.project.photo, 'public-read', objectInfo);
        setProperties.photo = location;
      }

      const entityProperties = await projectService.updateProject(
        this.id,
        setProperties,
        unsetProperties
      );
      const photo = entityProperties.get('photo');
      this.updateProjectStore({
        ...this.formFields,
        videos: {
          redboxPitch: this.formFields.video1min,
          redboxFundingPitch: this.formFields.video8min,
          blueboxPitch: this.formFields.blueboxVideo,
          goldboxPitch: this.formFields.goldboxVideo,
          learningsVideo: this.formFields.learningsVideo
        },
        id: this.id,
        photo
      });

      if (!await projectService.canIModifyProject(this.id)) {
        if (this.project.isRejected) {
          this.$store.commit('removeRejectedProject', this.project);
        } else {
          this.$store.commit('removeProject', this.project);
        }
      }
      return this.$router.push({ name: this.goToAfterSave });
    },
    changeProjectPhase() {
      return projectService.changeProjectPhase(this.selectedPhase, { id: this.id })
        .then(this.updateStoreProjectPhase);
    },
    manageProjectPhaseDuration() {
      return phaseService.managePhaseDays(this.phaseId, this.phaseDuration)
        .then(this.updateStoreProjectPhase);
    },
    updateStoreProjectPhase(phase) {
      this.phaseId = phase.id;
      const updatedProject = {
        id: this.id,
        phase: {
          id: phase.id,
          name: phase.get('name'),
          title: phase.get('name').indexOf('redbox') > -1
            ? `${phase.get('title')} ${phase.get('subTitle')}`.trim()
            : phase.get('title'),
          subTitle: phase.get('subTitle'),
          fullTitle: `${phase.get('title')} ${phase.get('subTitle') || ''}`.trim(),
          endDate: Moment(phase.get('endDate')).format('MMM DD, YYYY')
        }
      };
      this.updateProjectStore(updatedProject);
    },
    async updateCrossCorporate() {
      const crossCorporate = this.crossCorporate
        .map((companyId) => this.companiesMap.get(companyId));
      await projectService.updateCrossCorporateProject(
        this.project.id, crossCorporate, this.project
      );
    },
    async removeCrossCorporate() {
      const entity = this.getValidatedEntityProperties({
        crossCorporate: this.crossCorporate
      });
      await this.saveProject(entity);
      const updatedProject = {
        id: this.id,
        crossCorporate: []
      };
      return this.updateProjectStore(updatedProject);
    },
    addCreditFromProject() {
      const { bankAccount: { id: bankAccountId } } = this.project;
      const amount = Number(this.coinBalanceDelta);
      projectService.addCreditToProject(bankAccountId, amount);
      return this.updateProjectStore({
        id: this.id,
        coinBalance: this.project.coinBalance + amount
      });
    },
    removeCreditFromProject() {
      const { bankAccount: { id: bankAccountId } } = this.project;
      const amount = Math.abs(Number(this.coinBalanceDelta));
      projectService.removeCreditFromProject(bankAccountId, amount);
      return this.updateProjectStore({
        id: this.id,
        coinBalance: this.project.coinBalance - amount
      });
    },
    resetValuesIfAlumni() {
      this.selectedPhase = null;
      this.phaseDuration = 0;
    },
    async manageEmailCampaign() {
      if (this.hadEmailCampaign && !this.hasEmailCampaign) {
        await projectService.unsubscribeToEmailCampaign(this.project.id);
      } else if (!this.hadEmailCampaign && this.hasEmailCampaign) {
        const campaignInfo = {
          date: this.campaignDateSelected,
          coachingNumber: this.coachingNumber
        };
        await projectService.subscribeToEmailCampaign(
          this.project.id, this.creatorEmail, campaignInfo
        );
      }
      const updatedProject = {
        id: this.id,
        emailCampaign: this.campaignDateSelected
      };
      this.updateProjectStore(updatedProject);
    },
    onOneMinuteVideoUploadProgress(event) {
      this.oneMinuteVideoUploadProgress = event;
      if (!this.uploadInProgress && this.hasSubmitIntention) {
        // upload complete and auto save
        this.submit();
      }
    },
    onEightMinuteVideoUploadProgress(event) {
      this.eightMinuteVideoUploadProgress = event;
      if (!this.uploadInProgress && this.hasSubmitIntention) {
        // upload complete and auto save
        this.submit();
      }
    },
    onBlueboxVideoUploadProgress(event) {
      this.blueboxVideoUploadProgress = event;
      if (!this.uploadInProgress && this.hasSubmitIntention) {
        // upload complete and auto save
        this.submit();
      }
    },
    onGoldboxVideoUploadProgress(event) {
      this.goldboxVideoUploadProgress = event;
      if (!this.uploadInProgress && this.hasSubmitIntention) {
        // upload complete and auto save
        this.submit();
      }
    },
    onLearningsVideoUploadProgress(event) {
      this.learningsVideoUploadProgress = event;
      if (!this.uploadInProgress && this.hasSubmitIntention) {
        // upload complete and auto save
        this.submit();
      }
    },
    async ownerChanged(project) {
      this.showChangeOwnerDialog = false;
      const projectProperties = await userService.updateChangeOwnerStore(project);
      this.form = { ...this.form, ...projectProperties };
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  color: rgba(0, 0, 0, .54);
  font-size: 12px;
}

.croppa-container {
  border: 1px solid grey;
}
</style>
<style lang="scss">
.videoError label {
  color: #ff4a4a;
}

.sponsorsError {
  & .v-label,
  & .v-subheader {
    color: #ff4a4a;
  }
}

.check-box .input-group__details {
  min-height: 0 !important;
}

.sponsorField {
  width: 90%;
}
</style>
